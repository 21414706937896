<template>
  <v-card>
    <v-card-title v-if="showTitle">
      {{ title }}
    </v-card-title>
    <v-divider/>
    <v-card-text class="pt-5">
      <slot v-bind="{form, errors}" name="fields"></slot>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-btn :disabled="loading" :loading="loading" class="primary-button" color="primary" @click="confirm">Salveaza</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: {
    actionUrl: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      settingManager: this
    }
  },
  data () {
    return {
      form: {},
      errors: {},
      loading: false
    }
  },
  methods: {
    confirm () {
      this.loading = true
      this.errors = {}
      this.$http.post(this.actionUrl, this.form)
        .then(({data}) => {
          this.$emit('saved', data)
          this.loading = false
          this.$vs.notify({
            title: 'Succes!',
            text: 'Setarile au fost salvate cu succes!',
            color: 'success'
          })
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })
    },
    loadData () {
      this.form = {}
      this.$http.get(this.actionUrl)
        .then(({data}) => {
          this.form = data
        })
        .catch(({response}) => {
          if (response.status === 404) {
            this.$vs.notify({
              title: 'Eroare',
              text: 'API-ul nu exista! Va rugam verificati!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la preluare setari curente, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
